import { ILocalization } from '../types/index';

export interface IResponseData {
    contextData: IContextData;
    breadcrumb: IBreadcrumbData;
    mixedMedia: IMixedMediaData;
    orderModule: IOrderModuleData;
    variantSelection: IVariantSelection;
    bookmark: IBookmarkArticleData;
    workWearStoreAvailability: IWorkWearStoreAvailability;
    glovesRecommendation: IGlovesRecommendation[];
    shutterModule: IShutterModule;
    bannerModule: IBannerModule;
    purchaseAdvice: IPurchaseAdvice;
    allRichTexts: IRichTextItemsInternal[];
    state: IDetailsPageReadModelState;
}

export interface IDetailsPageReadModelState {
    name: string;
}

export interface IRichTextItemsInternal {
    id: string;
    items: IRichTextItemInternal[];
}

export interface IRichTextItemInternal {
    nodeType: NodeType;
    children: IRichTextItemInternal[];
    markups: string[];
    textValue: string;
    indent: number;
    link: ILinkInfo;
}

export interface ILinkInfo {
    href: string;
    openInNewTab: boolean;
}

export enum NodeType
{
    Paragraph,
    Text,
    LineBreak,
    List,
    ListItem,
    BlankLine
}

export interface IVariantChangedEventData {
    masterArticleNo: number;
    colorCode: number;
}

export interface IBreadcrumbData {
    category: ICategory;
}

export interface ICategory {
    folderId: string;
    headerImageKey: string;
    name: string;
    navigationKey: string;
    navigationKeyPath: string;
    path: string;
    rgbColorCode: string;
    seoSlug: string;
    webStyle: string;
}

export interface IDeliveryInformation {
    maxValue: number;
    minValue: number;
    periodType: number;
    calendarWeek: number;
}

export interface IMixedMediaData {
    masterArticleNo: number;
    colorCode: number;
    isNew: boolean;
    altTagForImages: string;
    image: IImage;
    mainActionImage: IImage;
    additionalInfoImages: IImage[];
    has3DView: boolean;
    secondaryImage: IImage;
    detailedImage: IImage;
    shoeSoleImage: IImage;
    salesArticleType: SalesArticleType;
    showMainImageInFirstPlaceByEsClass: boolean;
}

export interface IImage {
    fullPath: string;
    path: string;
    srcSet: ISrcSet;
}

export interface ISrcSet {
    urls: string;
    sizes: string;
}

export interface IOrderModuleData {
    articleInfo: IArticleInfo;
    articlePrice: IArticlePrice;
    availabilityState?: number;
    deliveryInformation: IDeliveryInformation;
    maxQuantityPerOrder: number;
    shareButtonUrl: string;
    countryDependentContents: ICountryDependentContentsModel;
}

export interface ICountryDependentContentsModel {
    defaultCountryDependentContentsText: string;
    countryDependentContentsAttributeValue: string;
}

export interface IArticleInfo {
    masterArticleNo: number;
    packagingInformation: IPackagingInformation;
    salesArticleNo: string;
    salesArticleVariantKey: string;
    title: string;
    isRemainingStock: boolean;
    brand: string;
}

export interface IPackagingInformation {
    packageSize: number;
    quantityUnit: IQuantityUnit;
    subQuantityUnit?: IQuantityUnit;
}

export interface IQuantityUnit {
    isoCode: string;
    plural: string;
    quantityUnitCode: number;
    singular: string;
}

export interface IArticlePrice {
    basicPrice: IPrice;
    priceDeduction?: number;
    hasRecommendedRetailPrice: boolean;
    minimalOriginalPrice: IPrice;
    currency: ICurrency;
    priceBuildGroup: number;
    scalePrices?: IScalePrice[];
    hideScalePrices: boolean;
    flagAdvertisedAsCostFreeInSet: boolean;
}

export interface IPrice {
    grossValue: number;
    netValue: number;
    basePrice?: IBasePrice;
}

export interface IBasePrice extends IPrice {
    quantity: number;
    unit: string;
}

export interface ICurrency {
    isoCode: string;
    symbol: string;
    symbolShortForm: string;
    prependCurrencySymbol: boolean;
}

export interface IScalePrice {
    unit: string;
    price: IPrice;
    quantity: number;
}

export interface IVariantSelection {
    availableColors: IAvailableColor[];
    availableModels: IAvailableModel[];
    availableSizeTypes: ISizeType[];
    translationUrls: ITranslationUrls[];
    isDefaultSize: boolean;
    selectedColor?: IColor;
    selectedModel?: IModel;
    selectedSize?: ISize;
    selectedSizeType?: string;
    showSizeSwitcher: boolean;
    showColorSwitcher: boolean;
    showModelSwitcher: boolean;
    isScrew: boolean;
    showSizeTypesAsTabs: boolean;
    sizeTableType: string;
}

export interface IColor {
    code: number;
    name: string;
}

export interface IAvailableColor extends IColor {
    iconPath: string;
    defaultArticleImagePath: string;
    salesArticleVariantKey: string;
}

export interface IModel {
    code: number;
    label: string;
}

export interface ITranslationUrls {
    culture: string;
    url: string;
}

export interface IAvailableModel extends IModel {
    salesArticleVariantKey: string;
    defaultArticleImagePath: string;
}

export interface ISizeType {
    sizeTypeCode: number;
    sizeTypeName: string;
    sizes: ISalesArticleVariantSize[];
}

export interface ISalesArticleVariantSize {
    salesArticleVariantKey: string;
    size: ISize;
}

export interface ISize {
    code: number;
    fittingAccuracy?: number;
    label: string;
    name: string;
}

export interface IBookmarkArticleData {
    productName: string;
    colorName: string;
    sizeName: string;
    modelName: string;
    imageUrl: string;
    savKey: string;
    categoryName: string;
}

export interface IWorkWearStoreAvailability {
    availableColors: IAvailableColor[];
    availableSizeTypes: ISizeType[];
    availableModels: IAvailableModel[];
}

export interface IWorkWearStoreAvailabilityData {
    masterArticleNo: number;
    availableInAnyStore: boolean;
    variantsStoreInformation: IVariantsStoreInformation[];
}

export interface IVariantsStoreInformation {
    retailStoreName: string;
    retailStoreNo: number;
    retailStoreId: string;
    displayOrder: number;
    variants: IArticleVariant[];
}

export interface IWorkWearStoreColor {
    salesArticleVariantKey: string;
}

export interface IWorkWearStoreSizeType {
    sizeTypeCode: number;
    sizeTypeName: string;
    sizes: IWorkWearStoreSize[];
}

export interface IWorkWearStoreSize {
    code: number;
    label: string;
    name: string;
}

export interface ArticleDataInjectionRequest {
    requester: string;
}

export interface LogoCreatorSuccessResponse {
    smakeId: number;
    articles: Array<LogoCreatorSuccessArticle>;
    masterArticleNumber: number;
    reporting: IPiwikReporting;
    itemOrigin: string;
}

export interface LogoCreatorSuccessArticle {
    quantity: number;
    savKey: string;
}

export interface LogoCreatorOpenFromBasketModalPayload {
    smakeId: number;
}

export interface IBookmarkArticleDataResponse extends IBookmarkArticleData, ArticleDataInjectionRequest { }

export interface IShutterModule {
    glovesRecommendation: IGlovesRecommendation[];
    hasGlovesRecommendation: boolean;
    productInfo: IProductInfo;
    additionalDescriptions: IAdditionalDescription[];
    layoutedContentGridKeys: string[];
    productFeaturesGroups: IProductFeaturesGroup[];
}

export interface IAdditionalDescription {
    rows: IAdditionalDescriptionRow[];
    l10n: ILocalization;
    has3DView: boolean;
    threeDContainerId: string;
    fallBackImageUrl: string;
    debuggerIsOn: boolean;
    documentKey: string;
    isRelease: boolean;
}

export interface IAdditionalDescriptionRow {
    cells: IAdditionalDescriptionCell[];
    addRowSeparator: boolean;
    types: string[];
    theme: string;
}

export interface IAdditionalDescriptionCell {
    contents: IAdditionalDescriptionElement[];
    flexCellGrowth: number;
    types: string[];
}

export interface IAdditionalDescriptionElement {
    types: string[];
    contentType: string;
}

export enum AspectRatio {
    Portrait,
    Landscape,
}

export enum AdditionalDescriptionImageScale {
    FullWidth,
    Half,
    TwoThirds,
    Third,
}

export interface IAdditionalDescriptionLink extends IAdditionalDescriptionElement {
    icon: string;
    linkText: string;
    target: string;
    openInNewWindow: boolean;
    isDownloadable?: boolean;
    l10n: ILocalization;
}

export interface IAdditionalDescriptionShowcase extends IAdditionalDescriptionElement {
    masterArticleNo: number;
    colorCode: number;
    index: number;
    prefix?: string;
    resumeInBackground: boolean;
    threeDContainerId: string;
    fallBackImageUrl: string;
}

export interface IAdditionalDescriptionText extends IAdditionalDescriptionElement {
    value: string;
}
export interface IAdditionalDescriptionTitle extends IAdditionalDescriptionElement {
    text: string;
}
export interface IAdditionalDescriptionUnprocessedTitle extends IAdditionalDescriptionTitle {
    inASeparateRow: boolean;
}

export interface IAdditionalDescriptionVideo extends IAdditionalDescriptionElement {
    mp4: string;
    ogv: string;
    webM: string;
    previewImage: IAdditionalDescriptionImage;
}

export interface IAdditionalDescriptionPdf extends IAdditionalDescriptionElement {
    masterArticleNo: number;
    colorCode: number;
    index: number;
    prefix: string;
    resumeInBackground: boolean;
    aspectRatioHorizontal?: number;
    aspectRatioVertical?: number;
}

export interface IAdditionalDescriptionImage extends IAdditionalDescriptionElement {
    url: string;
    altText: string;
    title: string;
    scale: string;
    aspectRatio: AspectRatio;
    width: number;
    height: number;
}

export interface IArticleVariant {
    salesArticleNo: string;
    colorCode: number;
    modelCode: number;
    sizeCode: number;
    availabilityStatus: AvailabilityStatusEnum;
}

export enum AvailabilityStatusEnum {
    InStock,
    CurrentlyOutOfStock,
    NotInStock,
}

export interface IBannerModule {
    showOnlineCatalog: boolean;
    showLogoService: boolean;
    showGloves: boolean;
    showShoes: boolean;
    isOnRightPage: boolean;
    logoServiceRedirectUrl: string;
    glovesRedirectUrl: string;
    shoesRedirectUrl: string;
    onlineCatalogIncVat: IOnlineCatalogFlap;
    onlineCatalogExVat: IOnlineCatalogFlap;
}

export interface IOnlineCatalogPage {
    previewImageUrl: string;
    redirectUrl: string;
}

export interface IOnlineCatalogFlap {
    leftPage: IOnlineCatalogPage;
    rightPage: IOnlineCatalogPage;
}

export interface IProductInfo {
    image: IImage;
    claim: IClaim;
    emotionalText: IEmotionalText;
    description: IProductDescription; // Description tab
    details: ISpecificationEntry[]; // Details tab
    extras: IProductFeatureInExtras[]; // Extras tab
    disableProductInfoShutter: boolean;
    preferAdditionalDescriptionShutter: boolean;
    IsArticleSet: boolean; // different description tab
}

export interface IDisclaimer {
    name: string;
    structuredText: IRichTextReference;
    rtfText: string | null;
    dataMode: number;
    globalRank: number;
}

export interface IProductFeaturesGroup {
    groupName: string;
    features: IFeature[];
}

export interface IFeature {
    showCase: string;
    logo: ILogo;
    emotionalDescriptionTitle: string;
    emotionalDescriptionText: IRichTextReference;
}

export interface IRichTextItems {
    id: string;
    items: ITextItem[];
}
export interface IRichTextReference {
    id: string;
}

export interface ILogo {
    imageUrl: string;
    altText: string;
}

export interface ITextItem {
    type: string;
    children: ITextItemChild[];
}

export interface ITextItemChild {
    text: string;
    markups: string[];
    children: IFormattedText[];
}

export interface IFormattedText {
    text: string;
    markups: string[];
}

export interface IEmotionalText {
    title: string;
    text: IRichTextReference;
}

export interface IClaim {
    title: string;
    text: IRichTextReference;
}

export interface IGlovesRecommendation {
    rate: number;
    title: string;
}

export interface IProductDescription {
    orderedDisclaimers: IDisclaimer[];
    functionLayers: IFunctionalLayer[];
    links: IProductDescriptionLinks;
    bullets: IRichTextReference | null;
    commonRtfTextFromWsd: string;
    dataMode: number;
    careInstructions: ICareLabel[];
    materials: IMaterial[];
    shoeWeights: IShoeWeight[];
    isSmakeDesignerAvailable: boolean;
    isKidArticle: boolean;
    articleSetItemDescriptions: IArticleSetItem[];
}

export interface IArticleSetItem { 
    colorText: string;
    modelText: string;
    sizeText: string;
    isSellableSeparately: boolean;
    linkToDetailsPage: string;
    quantity: number;
    flagAdvertisedAsCostFreeInSet: boolean;
    masterArticleNo: number;
    salesArticleVariantKey: string;
    salesDesignation: string;
}

export interface ICareLabel {
    description: string;
    icon: string;
}

export interface IShoeWeight {
    sizeLabelCode: number;
    weight: number;
}

export interface IMaterial {
    layers: IMaterialLayer[];
    additionalInformation: string[];
}

export interface IMaterialLayer {
    name: string;
    grammage: number;
    fibers: IMaterialFiber[];
}

export interface IMaterialFiber {
    name: string;
    percentage: number;
}

export interface ISpecificationEntry {
    name: string;
    orderedValues: string[];
}

export interface IProductFeatureInExtras {
    name: string;
    title: string;
    structuredText: IRichTextReference | null;
    rtfText: string | null;
    image: ILogo;
    dataMode: number;
}

export interface IFunctionalLayer {
    key: string;
    redirectUrl: string;
    imageName: string;
    buttonText: string;
}

export interface IProductDescriptionLinks {
    dataSheets: IDocumentLink[];
    logoServiceInfoUrl: string | undefined;
}

export interface IDocumentLink {
    title: string;
    url: string;
}

export interface IPurchaseAdvice {
    showJacketFinder: boolean;
    showTrouserFinder: boolean;
    showShoeFinder: boolean;
    jacketFinderRedirectUrl: string;
    trouserFinderRedirectUrl: string;
    shoeFinderRedirectUrl: string;
}

export interface IContextData {
    culture: string;
    portal: string;
    portalMandantToken: string;
    apiMagicLink: string;
    originalUrl: string;
    baseUrlForAssets: string;
    actualRequestCookies: { key: string; value: string }[];
    customerData: ICustomerData;
    isMobile: boolean;
    isEsApp: boolean;
    priceMode?: 'Gross' | 'Net';
    wwsId: string;
    isRelease: boolean;
    featureToggles: IFeatureToggles;
    numberFormat: INumberFormat;
}

export interface IFeatureToggles {
    redesignOrderModal: boolean;
    enableLogoCreator: boolean;
    enableRedesignCategoryPageAndSearchPage: boolean;
    enableFullBasketLayout: boolean;
    piwikProEnableEcommerceTracking: boolean;
    useNewAccountService: boolean;
    enableArticleSetInformationInPDP: boolean;
    enablePersonalizationBannerOnDetailsPage: boolean;
    enableMetaPixel: boolean;
}

export interface INumberFormat {
    numberDecimalSeparator: string;
    numberGroupSeparator: string;
}

export enum PiwikEvent {
    EcommerceProductDetailView = 'ecommerceProductDetailView',
}

export enum MetaEvent {
    ViewContent = 'ViewContent',
    AddToCart = 'AddToCart',
}


export interface ICustomerData {
    ssoId: string;
    customerNumbers: [number];
    hasCustomerPrices?: boolean;
    priceLevels: [];
    flagVatFree?: boolean;
    postageFree?: string;
}

export enum ImageTypesEnum {
    MainImage,
    MainActionImage,
    AdditionalImages,
    SecondaryImage,
    DetailedImage,
    ShoeSoleImage,
}

export enum LayoutTypesEnum {
    Single_Element,
    Two_Elements,
    Three_Elements,
}

export enum SalesArticleType {
    NormalArticle,
    VoucherArticle,
    ArticleSet,
}

export interface ITranslationFragment {
    translations: ITranslationFragmentObject[];
}

export interface ITranslationFragmentObject {
    code: string,
    obfuscation: string,
    url: string,
}


export enum VariantType {
    Color,
    Size,
    Special,
    Model,
    None,
    Kiosk, // special type for kiosk only. If we have color, show color panel. Otherwise, show size panel
}

export interface IBasketModalForLogoCreatorSizeVariant {
    savKey: string;
    quantity: number;
    netPrice: number;
    label: string;
}

export interface IBasketModalForLogoCreator {
    smakeId: number;
    title: string;
    color?: string;
    colorName?: string;
    sizesWithPrice?: Array<IBasketModalForLogoCreatorSizeVariant>;
    imageUrl: string;
    exampleSalesVariantKey: string;
    model?: string;
    modelLabel?: string;
    masterArticleNumber: number;
    isStraussBrand: boolean;
    nativeNavKeyPath?: string;
    reporting: IPiwikReporting;
    itemOrigin: string;
    categoryName?: string;
}

export interface IPiwikReporting {
    customizations: string;
    price: {
        value: number;
        currency: string;
    }
}
